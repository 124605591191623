@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-firework-1 { --tx: 50px; --ty: -60px; }
  .animate-firework-2 { --tx: -40px; --ty: -50px; }
  .animate-firework-3 { --tx: 60px; --ty: -55px; }
  .animate-firework-4 { --tx: -50px; --ty: -45px; }
  .animate-firework-5 { --tx: 45px; --ty: -65px; }
  .animate-firework-6 { --tx: -55px; --ty: -50px; }
}


.cursor-grab {
  cursor: grab !important;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

/* 3D transform support */
.transform-style-3d {
  transform-style: preserve-3d;
}

/* Optional: Add smooth transitions */
.transition-drag {
  transition: transform 0.2s ease-in-out;
}

/* Optional: Add some depth to dragged items */
.dragging {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}